import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import useFormContextHook from '../../../../hooks/useFormContextHook';
import empty_bowl from '../../../../assets/Icon/empty_bowl.png';

import ContetnsItem from './ContetnsItem';
import Filter from '../Filter';
import { ALL_DATA } from '../../../../data';

const StoredContents = () => {
  const { watch } = useFormContextHook();
  const storedPoseValue = watch('storedPose') ?? [];
  const peopleCountFilter = watch('peopleCountFilter', 0);

  const storedData = ALL_DATA.filter((el) => {
    if (storedPoseValue.includes(el.id)) {
      if (peopleCountFilter === 0) return true;

      return el.peopleCount === peopleCountFilter;
    }
    return false;
  });

  return (
    <Wrapper
      transition={{
        duration: 0.2,
      }}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
      }}
      exit={{ opacity: 0 }}
    >
      <Filter />
      {storedPoseValue.length === 0 ? (
        <EmptyWrapper>
          <img src={empty_bowl} />
          <span>저장된 포즈가 없어요.</span>
        </EmptyWrapper>
      ) : (
        <ListWrapper>
          {storedData.map((item) => {
            return <ContetnsItem key={item.id} data={item} stored />;
          })}
        </ListWrapper>
      )}
    </Wrapper>
  );
};

export default StoredContents;

const Wrapper = styled(motion.div)`
  width: 100%;
  height: auto;
`;

const ListWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 0 20px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  overflow-y: auto;
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 200px 0 100px;

  gap: 20px;

  img {
    width: 70px;
  }

  span {
    color: #1e2024;
    font-size: 1rem;
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.01rem;
  }
`;
