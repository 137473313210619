import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import HomeHeaderImage from '../../../../assets/Image/HomeHeaderImage.png';

const HomeHeader = () => {
  return (
    <Wrapper
      transition={{
        duration: 0.2,
      }}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
      }}
      exit={{ opacity: 0 }}
    >
      <Title>
        추억 남기기 좋은
        <br />
        저녁이에요
      </Title>
      <Image src={HomeHeaderImage} />
    </Wrapper>
  );
};

export default HomeHeader;

const Wrapper = styled(motion.div)`
  width: 100%;
  padding: 2.5rem 1.5rem 0.75rem 1.25rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const Title = styled.div`
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  /* or 34px */

  letter-spacing: -0.01em;

  color: #1e2024;
`;

const Image = styled.img`
  width: 64px;
  height: auto;
`;
