import styled from '@emotion/styled';
import { ActivityComponentType } from '@stackflow/react';
import { motion } from 'framer-motion';

import { AppScreen } from '../../stackflow/components';
import arrowback from '../../assets/Icon/arrow-back.png';
import ControlBox from './ControlBox';
import { usePathParams } from '../../stackflow/hooks/usePathParams';
import { ALL_DATA } from '../../data';

import AppLogo from '../../assets/Image/AppLogo.png';
import { useFlow } from '../../stackflow';

const DetailPage: ActivityComponentType = () => {
  const { id } = usePathParams();

  const { replace } = useFlow();

  const isApp = localStorage.getItem('isApp');

  const detailData = ALL_DATA.find((el) => {
    return el.id === id;
  });

  if (!detailData) {
    return <div>데이터가 없습니다.</div>;
  }

  return (
    <AppScreen
      appBar={
        isApp
          ? {
              border: false,
              backButton: {
                renderIcon: () => <Icon src={arrowback} />,
              },
            }
          : {
              border: false,
              backButton: {
                render: () => <></>,
              },
              closeButton: {
                render: () => <></>,
              },
              appendLeft: () => (
                <AppBarTitle
                  onClick={() => {
                    replace('HomePage', {});
                  }}
                >
                  <img src={AppLogo} />
                  <span>포즈위키</span>
                </AppBarTitle>
              ),
            }
      }
    >
      <Wrapper
        transition={{
          duration: 0.2,
        }}
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
        }}
        exit={{ opacity: 0 }}
      >
        <ImageWrapper>
          <Image src={detailData?.src} />
        </ImageWrapper>

        <ControlBox data={detailData} />
      </Wrapper>
    </AppScreen>
  );
};

export default DetailPage;

const Wrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  flex: 1;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const ImageWrapper = styled.div`
  width: 100%;
  padding: 8px 20px;
  overflow: hidden;
  border-radius: 0;
  border: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Image = styled.img`
  width: 100%;
  min-height: 300px;
  max-width: 500px;
  object-fit: contain;

  background: #f3f4f5;
  border-radius: 0.75rem;
  border: none;
  box-sizing: border-box;

  height: auto;
  display: inline-block;
  border-style: hidden;
  border: 0px;
  border-width: 0px;
`;

const AppBarTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 5px;

  span {
    color: #000;
    font-size: 1.1rem;
    font-family: Pretendard;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.015rem;
  }

  img {
    width: 25px;
  }
`;
