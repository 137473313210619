import { SOLO_DATA } from './solo';
import { TWO_DATA } from './two';

export type PRIKURA_ITEM = {
  id: string | number;
  src: string;
  peopleCount: number;
  originLink?: string;
  name?: string;
};

export const ALL_DATA: PRIKURA_ITEM[] = [
  ...SOLO_DATA.map((el) => {
    return { ...el, id: `solo-${el.id}` };
  }),
  ...TWO_DATA.map((el) => {
    return { ...el, id: `two-${el.id}` };
  }),
];
