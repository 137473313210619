import HomeHeader from './HomeHeader';
import StoredHeader from './StoredHeader';

interface Props {
  navigationValue: string;
}

const Header = ({ navigationValue }: Props) => {
  if (navigationValue === 'stored') {
    return <StoredHeader />;
  }
  return <HomeHeader />;
};

export default Header;
