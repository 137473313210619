import styled from '@emotion/styled';
import useFormContextHook from '../../../../hooks/useFormContextHook';

const Filter = () => {
  const { watch, setValue } = useFormContextHook();
  const peopleCountFilter = watch('peopleCountFilter', 0);

  const handlePeopleCountFilterChange = (value: number) => {
    setValue('peopleCountFilter', value);
  };

  return (
    <Wrapper>
      {PEOPLE_COUNT_FILTER.map((el) => {
        return (
          <ItemChip
            key={el.value}
            onClick={() => handlePeopleCountFilterChange(el.value)}
            selected={peopleCountFilter === el.value}
          >
            {el.label}
          </ItemChip>
        );
      })}
    </Wrapper>
  );
};

// 인원수 필터 리스트
const PEOPLE_COUNT_FILTER = [
  {
    label: '전체',
    value: 0,
  },
  {
    label: '혼자',
    value: 1,
  },
  {
    label: '2명',
    value: 2,
  },
  // {
  //   label: '3명',
  //   value: 3,
  // },
  // {
  //   label: '4명',
  //   value: 4,
  // },
  // {
  //   label: '5명',
  //   value: 5,
  // },
];

export default Filter;

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 999;
  background: white;

  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  padding: 0.75rem 1.25rem;

  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ItemChip = styled.div<{ selected?: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.75rem 1rem;
  gap: 0.375rem;

  border: 1px solid #e6e8ea;
  border-radius: 32px;
  flex-shrink: 0;

  /* Body/Bold 2 */
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.01em;

  ${({ selected = false }) => {
    if (selected) {
      return `
        font-weight: 600;
        background: #1E2024;
        color: white;
      `;
    }
  }}
`;
