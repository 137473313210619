import React from 'react';
import HomeContents from './HomeContents';
import StoredContents from './StoredContents';

interface Props {
  navigationValue: string;
}

const Contents = ({ navigationValue }: Props) => {
  if (navigationValue === 'stored') {
    return <StoredContents />;
  }
  return <HomeContents />;
};

export default Contents;
