import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';

type Props = {
  message: string;
  autoHideDuration?: number;

  open: boolean;
  setOpen: (open: boolean) => void;
};

export default function SnackBar({ message, autoHideDuration = 600, open, setOpen }: Props) {
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      message={message}
    />
  );
}
