import { PRIKURA_ITEM } from '.';

export const TWO_DATA: PRIKURA_ITEM[] = [
  {
    id: 1,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FKIxuf%2FbtskgHOZswv%2FWHkaZ9ZODp1y8qOr0XXd70%2Fimg.jpg',
    name: '한지민',
    peopleCount: 2,
  },
  {
    id: 2,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FokAdB%2FbtskuMgj3Od%2FfkiKioihH63qIUoGverNN1%2Fimg.jpg',
    peopleCount: 2,
  },
  {
    id: 3,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fdk1x7G%2Fbtskln9wmU6%2FbPrzfqMmKylyaGtBeH4bk1%2Fimg.jpg',
    peopleCount: 2,
  },
  {
    id: 4,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Ftl3ce%2Fbtskge0lPBl%2FvKUFmz4iTeeykMi3kDk3Kk%2Fimg.jpg',
    peopleCount: 2,
  },
  {
    id: 5,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FqUKcP%2FbtskrmhUFx9%2FElHvAFax5V382UZ2kNkUL1%2Fimg.jpg',
    peopleCount: 2,
  },
  {
    id: 6,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FdwcAFc%2Fbtskg9xqbZw%2FbIfBkgMgflFIoIDZ0P7hYk%2Fimg.jpg',
    peopleCount: 2,
  },
  {
    id: 7,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FoObNJ%2FbtskgH2u49Z%2FatWtgN8zwkmBgLWKmIx960%2Fimg.png',
    peopleCount: 2,
  },
  {
    id: 8,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcMEAmA%2FbtskgHuDRww%2F8zTvAtE9sBCtKdywNErk5K%2Fimg.png',
    peopleCount: 2,
  },
  {
    id: 9,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fruu71%2Fbtskhb26B1X%2FDrSYfA3xnIrGKkMlR9Otbk%2Fimg.png',
    peopleCount: 2,
  },
  {
    id: 10,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcSQt0M%2Fbtskiy4AIGi%2FD2Y3k6TikaEKMdfBojDnf0%2Fimg.png',
    peopleCount: 2,
  },
  {
    id: 11,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FltPp1%2FbtskuN7nx1f%2F0djEkV6XN3DQ2uY2L4wTKK%2Fimg.png',
    peopleCount: 2,
  },
  {
    id: 12,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fcoezbs%2FbtskgGWRfzt%2FWoZeE6HKk8Nf5RH7lF1z3K%2Fimg.png',
    peopleCount: 2,
  },
  {
    id: 13,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbWUskG%2FbtskuNlZDCW%2FZAZkKi4wtIANlMaLsJAwRK%2Fimg.jpg',
    peopleCount: 2,
  },
  {
    id: 14,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fy0dqc%2Fbtsklo8p8zQ%2FFmYJQSCPk6oeKfkoLpxLa1%2Fimg.jpg',
    peopleCount: 2,
  },
  {
    id: 15,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcvdoPO%2Fbtskg9KX5ty%2FJCoSskI8eO4EqkLijb1Hpk%2Fimg.jpg',
    peopleCount: 2,
  },
  {
    id: 16,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcvdoPO%2Fbtskg9KX5ty%2FJCoSskI8eO4EqkLijb1Hpk%2Fimg.jpg',
    peopleCount: 2,
  },
  {
    id: 17,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FEdxn6%2FbtskgIG9NEG%2FqiSTMDeStWc2BOnLq9sSm1%2Fimg.jpg',
    peopleCount: 2,
  },
  {
    id: 18,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F8xie5%2Fbtskll4V4pd%2Fx6PpOaCBUfO3QAoK9imW4k%2Fimg.jpg',
    peopleCount: 2,
  },
  {
    id: 19,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FdsWlJV%2FbtskuMHoOBK%2FdVdu9HHCZSh4Ylhxag5kY0%2Fimg.png',
    peopleCount: 2,
  },
  {
    id: 20,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FuiarB%2Fbtsklnn8Y85%2FueUc3rHkFMvaktpk6ZoSzK%2Fimg.png',
    peopleCount: 2,
  },
  {
    id: 21,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcfP8UI%2FbtskuP5bPTY%2F5MgT3YKd0Wkui1FPB4fcC1%2Fimg.png',
    peopleCount: 2,
  },
  {
    id: 22,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FRvW1X%2FbtsklnhpOmd%2FPOcoS2aDOkPM0dtIdOubF0%2Fimg.jpg',
    peopleCount: 2,
  },
  {
    id: 23,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FFpfGH%2FbtskiSWgjst%2FNgzRkrNCGByuwOlkMHqu60%2Fimg.jpg',
    peopleCount: 2,
  },
  {
    id: 24,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbN0HJu%2FbtskuXPHRsd%2FVn5FuQ3JFSvV2E2rh3fERk%2Fimg.jpg',
    peopleCount: 2,
  },
  {
    id: 25,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbvlsaB%2FbtskuPxlpf7%2F1dytUhCWNtAEjItaj7ozi1%2Fimg.jpg',
    peopleCount: 2,
  },
  {
    id: 26,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fdkeb6r%2FbtsknOseOfp%2F4mhZCnCRPpj75qM5fGupk0%2Fimg.png',
    name: '지석진',
    peopleCount: 2,
  },
  {
    id: 27,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fz1wPy%2Fbtskk8xJCNm%2Fh05r4wICy95i2v5xRyrUx1%2Fimg.jpg',
    name: '지석진',
    peopleCount: 2,
  },
  {
    id: 28,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbTzPfI%2FbtskrnHV0VQ%2FMuvjmR4PNOahI7ArmaiKWk%2Fimg.jpg',
    name: '지석진',
    peopleCount: 2,
  },
  {
    id: 29,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fbse66y%2FbtskgHIgUzM%2Fe1DBGNbXfXR0ry94EdMeSk%2Fimg.jpg',
    name: '지석진',
    peopleCount: 2,
  },
  {
    id: 30,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fc6kpJE%2FbtskrnA81rq%2FGbwVfxMQQBeATL5RxEkwqK%2Fimg.jpg',
    peopleCount: 2,
  },
  {
    id: 31,
    src: 'https://blog.kakaocdn.net/dn/UamE7/btsknve6KiU/crM7kPFf4k5Ut6Uta8wQ8K/img.jpg',
    peopleCount: 2,
  },
  {
    id: 32,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FsRDWx%2FbtskhKjUAgt%2FfkEBNyxfktIPOkTPfGwcY0%2Fimg.png',
    peopleCount: 2,
  },
  {
    id: 33,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FLqgDo%2Fbtskhct8SJN%2FDFbkwI25e4Z2rywoRqN3TK%2Fimg.png',
    peopleCount: 2,
  },
  {
    id: 34,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcnzDMI%2FbtskuOd8YhW%2FadhT4RAeaKJy2iDD9zAsLK%2Fimg.png',
    peopleCount: 2,
  },
  {
    id: 35,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcronaV%2Fbtskk85CqEj%2FaupKVt7QkST0vceyphxyM1%2Fimg.png',
    peopleCount: 2,
  },
  {
    id: 36,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FyBhsB%2FbtskuPxlWuV%2FrnafoAlPNFkTk5cdonAzkK%2Fimg.png',
    peopleCount: 2,
  },
  {
    id: 37,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fo2hm3%2FbtskguWdFMJ%2FR56pKfeOBqYoz6nZzfY8f1%2Fimg.png',
    peopleCount: 2,
  },
  {
    id: 38,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FoZaf5%2FbtskguBQTPJ%2FNKMKeoh2poq8VzRxbL0TLK%2Fimg.jpg',
    peopleCount: 2,
  },
  {
    id: 39,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbTwHKu%2FbtsknvTLJAe%2FvK3MKvS9PSPabvGnOMYmNk%2Fimg.jpg',
    name: '경우의수',
    peopleCount: 2,
  },
  {
    id: 40,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fc57jzH%2FbtskuNGjXme%2FrddOJpKkyGydHocFODypv1%2Fimg.jpg',
    name: '경우의수',
    peopleCount: 2,
  },
  {
    id: 41,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FV4rxS%2FbtskuPc3NKc%2F2jhnnviKYucgl87L54hsak%2Fimg.webp',
    name: '혜리',
    peopleCount: 2,
  },
  {
    id: 42,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FdRKHv5%2FbtskgHg9aol%2Fhlnkkm6Kd3jNSTZmEnHPWK%2Fimg.jpg',
    name: '잇지',
    peopleCount: 2,
  },
  {
    id: 43,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fbk93B1%2FbtskuOrGlvt%2FbQqKGrsiH12xn9tDIKIWpk%2Fimg.jpg',
    name: '잇지',
    peopleCount: 2,
  },
  {
    id: 44,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcgkYF8%2Fbtskie6lkoM%2F2qi2Mq2j7qNJFZSS1Q8HC0%2Fimg.jpg',
    name: '이성경',
    peopleCount: 2,
  },
  {
    id: 45,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F8leOJ%2Fbtskg9EdhSH%2FSGjbkiVHdkuWiIGvtX24tK%2Fimg.png',
    name: '이성경',
    peopleCount: 2,
  },
  {
    id: 46,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FDE3vS%2FbtskifEaYNa%2FxH39X1Nw9wek2Ub6H0xjMk%2Fimg.jpg',
    name: '류승용',
    peopleCount: 2,
  },
  {
    id: 47,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbNWzvS%2FbtskuNM5nBy%2FA8E2yLMNE4LhUZZZAlndSK%2Fimg.png',
    name: '박나래',
    peopleCount: 2,
  },
  {
    id: 48,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FmVaaf%2FbtskuMADmq9%2F2IazSb5kHk7DhHlcFbDu91%2Fimg.jpg',
    name: '손흥민짤',
    peopleCount: 2,
  },
  {
    id: 49,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcSxEt5%2FbtskuXCa2lg%2FTSZdsguEfhDpucjmQkiP0K%2Fimg.jpg',
    name: '손흥민',
    peopleCount: 2,
  },
];

/*


{
    id: 0,
    src: "",
    name: "",
    peopleCount: 2,
  },

  */
