import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { useMemo } from 'react';
import { ALL_DATA } from '../../../../data';
import useFormContextHook from '../../../../hooks/useFormContextHook';
import { Spacing } from '../../../common/Spacing';
import Filter from '../Filter';
import ContetnsItem from './ContetnsItem';

const HomeContents = () => {
  const { watch } = useFormContextHook();
  const peopleCountFilter = watch('peopleCountFilter', 0);

  const FilteredData = useMemo(
    () =>
      ALL_DATA.filter((el) => {
        if (peopleCountFilter === 0) return true;

        return el.peopleCount === peopleCountFilter;
      }).sort(() => Math.random() - 0.5),
    [peopleCountFilter]
  );

  return (
    <Wrapper
      transition={{
        duration: 0.2,
      }}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
      }}
      exit={{ opacity: 0 }}
    >
      <Filter />
      <Spacing height={8} />
      <ListWrapper>
        {FilteredData.map((item) => {
          return <ContetnsItem key={item.id} data={item} />;
        })}
      </ListWrapper>
    </Wrapper>
  );
};

export default HomeContents;

const Wrapper = styled(motion.div)`
  width: 100%;
  height: auto;
`;

const ListWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 0 20px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  overflow-y: auto;
`;
