import styled from '@emotion/styled';
import { useState } from 'react';
import heart_default from '../../assets/Icon/heart_default.png';
import heart_selected from '../../assets/Icon/heart_selected.png';

import share from '../../assets/Icon/share.png';
import { PRIKURA_ITEM } from '../../data';
import useFormContextHook from '../../hooks/useFormContextHook';

import { copyToClipboard } from '../../utils/link';
import SnackBar from '../common/SnackBar';

const ControlBox = ({ data }: { data: PRIKURA_ITEM }) => {
  const [openShareToast, setOpenShareToast] = useState(false);

  const { watch, setValue } = useFormContextHook();

  const storedPoseValue: (string | number)[] = watch('storedPose') ?? [];

  const isStored = storedPoseValue.includes(data.id);

  const handleShare = () => {
    const path = `https://prikura.pages.dev/detail/${data.id}`;
    copyToClipboard(`포즈위키👨‍👨‍👦‍👦 - 이렇게 찍어볼래? ${path}`);
    setOpenShareToast(true);
    // sendToNative('share', {
    //   title: '포즈위키👨‍👨‍👦‍👦',
    //   message: `이렇게 찍어볼래? ${path}`,
    // });
  };

  const handleStore = () => {
    if (storedPoseValue.includes(data.id)) {
      const filtered = storedPoseValue.filter((el) => el !== data.id);
      setValue('storedPose', filtered);
      return;
    }
    setValue('storedPose', [...storedPoseValue, data.id]);
    return;
  };

  return (
    <Wrapper>
      <StoreButtonWrapper onClick={handleStore}>
        <Icon src={isStored ? heart_selected : heart_default} />
      </StoreButtonWrapper>
      <ShareButtonWrapper onClick={handleShare}>
        <img src={share} />
        <label>공유하기</label>
      </ShareButtonWrapper>
      <SnackBar
        message="링크가 복사되었어요."
        open={openShareToast}
        setOpen={setOpenShareToast}
        autoHideDuration={700}
      />
    </Wrapper>
  );
};

export default ControlBox;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 16px 0;
`;

const StoreButtonWrapper = styled.div`
  padding: 16px;
  background: #f3f4f5;
  border-radius: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const ShareButtonWrapper = styled.div`
  padding: 16px 20px;

  background: #ffdbff;
  border-radius: 3.625rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  gap: 4px;

  img {
    width: 20px;
    height: 20px;
  }

  label {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: #261b28;
  }
`;
