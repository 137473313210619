import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import item_heart_default from '../../../../assets/Icon/item_heart_default.png';
import item_heart_selected from '../../../../assets/Icon/item_heart_selected.png';
import { PRIKURA_ITEM } from '../../../../data';
import useFormContextHook from '../../../../hooks/useFormContextHook';
import { useFlow } from '../../../../stackflow';

interface Props {
  data?: PRIKURA_ITEM;
  showHeart?: boolean;
  stored?: boolean;
}

const ContetnsItem = ({ data, showHeart = true, stored }: Props) => {
  const { push } = useFlow();
  const { watch } = useFormContextHook();

  const isStored = stored ? stored : watch('storedPose')?.includes(data?.id);

  const handleDetail = () => {
    push('DetailPage', { id: data?.id });
  };

  return (
    <Wrapper
      transition={{
        duration: 0.7,
      }}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
      }}
      exit={{ opacity: 0 }}
      onClick={handleDetail}
    >
      {data?.src && <Image src={data.src} />}
      {showHeart && (
        <HeartWrapper>
          {isStored ? (
            <HeartIcon src={item_heart_selected} />
          ) : (
            <HeartIcon src={item_heart_default} />
          )}
        </HeartWrapper>
      )}
    </Wrapper>
  );
};

export default ContetnsItem;

const Wrapper = styled(motion.div)`
  position: relative;
  width: 100%;

  height: 250px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  background: #f3f4f5;
  border-radius: 12px;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const HeartWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
`;

const HeartIcon = styled.img`
  width: 24px;
  height: 24px;
`;
