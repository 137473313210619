import styled from '@emotion/styled';
import { Dialog, DialogTitle } from '@mui/material';
import { useEffect } from 'react';

export interface SimpleDialogProps {
  open: boolean;

  onClose: () => void;
}

function DownloadAppDialog(props: SimpleDialogProps) {
  const { onClose, open } = props;

  const handleClose = () => {
    const isApp = localStorage.getItem('isApp');

    if (isApp) {
      onClose();
    }
  };

  useEffect(() => {
    const isApp = localStorage.getItem('isApp');

    if (isApp) {
      onClose();
    }
  }, [onClose]);

  const openDownloadAppHandler = () => {
    window.open('https://play.google.com/store/apps/details?id=com.devr.prikura', '_blank');
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>안내</DialogTitle>
      <Wrapper>
        <Title>계속 사용하시려면 앱을 다운받아주세요.</Title>
        <Button onClick={openDownloadAppHandler}>포즈위키 다운받기</Button>
      </Wrapper>
    </Dialog>
  );
}

export default DownloadAppDialog;

const Wrapper = styled.div`
  width: 100%;
  padding: 10px 24px 20px;
  gap: 30px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 17px;
  font-weight: 400;
  color: #1e2024;
`;

const Button = styled.div`
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  background: #ad4fcf;
  color: #fff;

  font-size: 16px;
  font-weight: 600;
  text-align: center;
`;
