import { PRIKURA_ITEM } from '.';

export const SOLO_DATA: PRIKURA_ITEM[] = [
  {
    id: 1,
    src: 'https://img1.daumcdn.net/thumb/R658x0.q70/?fname=https://t1.daumcdn.net/news/202009/23/newsen/20200923143522621sxnk.jpg',
    name: '한선화',
    peopleCount: 1,
  },
  {
    id: 2,
    src: 'https://img2.daumcdn.net/thumb/R658x0.q70/?fname=https://t1.daumcdn.net/news/202207/22/newsade/20220722090645022pl9i.jpeg',
    name: '홍지윤',
    peopleCount: 1,
  },
  {
    id: 3,
    src: 'https://img3.daumcdn.net/thumb/R658x0.q70/?fname=https://t1.daumcdn.net/news/202207/22/newsade/20220722090645004fa9w.jpeg',
    name: '윤두준',
    peopleCount: 1,
  },
  {
    id: 4,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FOM1VB%2Fbtsknu1BbTN%2F2W71BnL7KKskyRG1FU1htk%2Fimg.png',
    name: '태연',
    peopleCount: 1,
  },
  {
    id: 5,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FkOCaO%2Fbtskge0kZ85%2Fr72RyFYw2iPUnMkhM5hI9k%2Fimg.png',
    name: '태연',
    peopleCount: 1,
  },
  {
    id: 6,
    src: 'https://img1.daumcdn.net/thumb/R658x0.q70/?fname=https://t1.daumcdn.net/news/202207/22/newsade/20220722090645000za7m.jpeg',
    name: '선미',
    peopleCount: 1,
  },
  {
    id: 7,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FG9K4D%2FbtskiT8FNoX%2FBYqdeO3du4qe2QfkswKlf0%2Fimg.png',
    name: '스테이씨 아이사',
    peopleCount: 1,
  },
  {
    id: 8,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F514S5%2FbtskuPc1QLl%2FIchZDwUkJz9kqkoWD2iZok%2Fimg.png',
    name: '다이아 정채연',
    peopleCount: 1,
  },
  {
    id: 9,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FeFU2ZK%2FbtsknvlTPxW%2FyheEYLUvtUnjsM6qYMNAik%2Fimg.png',
    name: '프로미스나인 송하영',
    peopleCount: 1,
  },
  {
    id: 10,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FdBr8AH%2FbtskrcM7cSs%2Fws73hok7Cfp7llveptNPc0%2Fimg.png',
    name: '정예인',
    peopleCount: 1,
  },
  {
    id: 11,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fnnboc%2FbtskifKSCxC%2FkYKyESdUyXIChtrK6E0js0%2Fimg.png',
    name: '프로미스나인 장규리',
    peopleCount: 1,
  },
  {
    id: 12,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbyRMR0%2FbtskggqillE%2Fnpx7Br5kz1AACfhpUbKpb1%2Fimg.png',
    name: '위키미키 김도연',
    peopleCount: 1,
  },
  {
    id: 13,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FlmE28%2FbtskieZAHqC%2F5DTy8rLxNEiEhNQkM53cEK%2Fimg.png',
    name: '노제',
    peopleCount: 1,
  },
  {
    id: 14,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fb0dtDt%2FbtskiS9NAbh%2Ft8QKH5XzMIokZiUrJP80D1%2Fimg.png',
    name: '핫이슈 다나',
    peopleCount: 1,
  },
  {
    id: 15,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbgSvIs%2Fbtskgcg9ia9%2F7OBlodAPcE6Mz6sRk5Z8o1%2Fimg.png',
    name: '드림노트 미소',
    peopleCount: 1,
  },
  {
    id: 16,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fcl0xyQ%2Fbtskg9xphVH%2Foj3qJbMDUkDQkmxAWBvNk0%2Fimg.png',
    name: '우아 소라',
    peopleCount: 1,
  },
  {
    id: 17,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbMyfXV%2Fbtskk9Dp1Bd%2F6i5DRAnV8afVv3V8ASDcE1%2Fimg.png',
    name: '드림노트 미소',
    peopleCount: 1,
  },
  {
    id: 18,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbiFbQn%2FbtskgtXh8lN%2F1Ce8wcjfikkbwalYHuktwk%2Fimg.png',
    name: '프로미스나인 서연',
    peopleCount: 1,
  },
  {
    id: 19,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FT1eRn%2Fbtskk8EuLDn%2Fhnt4ySmTNBsmHmY5r7ynr1%2Fimg.png',
    name: '프로미스나인 채영',
    peopleCount: 1,
  },
  {
    id: 20,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FwzxVp%2FbtskgvALMcr%2FUC3Kd0pO9IZIsfU7rgAxz1%2Fimg.png',
    name: '프로미스나인 백지헌',
    peopleCount: 1,
  },
  {
    id: 21,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FW3Dor%2FbtskuXhRcFN%2F267nYfCaEPOegDcKo9uXyK%2Fimg.png',
    name: '블랙핑크 제니',
    peopleCount: 1,
  },
  {
    id: 22,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbW4KUD%2FbtskifjQpxB%2F4xmYeoubfWHwanP05eceJ1%2Fimg.png',
    name: '레드벨벳 예리',
    peopleCount: 1,
  },
  {
    id: 23,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbMm4tn%2FbtsknvzrVAK%2FYlJ3AADi1wESseTjUd4bH1%2Fimg.png',
    name: '한보름',
    peopleCount: 1,
  },

  {
    id: 24,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FUJ5Kt%2FbtsklotOo31%2FVjhc96HAuPV7OkphlMBIgK%2Fimg.png',
    name: '오연서',
    peopleCount: 1,
  },
  {
    id: 25,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fd3vNAC%2Fbtskk9Ka6rm%2F9k2b7EpuKyS39YmFt10dVK%2Fimg.png',
    name: '한소희',
    peopleCount: 1,
  },
  {
    id: 26,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F75nka%2Fbtskhc8L1iR%2F1taGGMThlZM0xChKwFK6k0%2Fimg.png',
    name: '송강',
    peopleCount: 1,
  },
  {
    id: 27,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcpSTOq%2Fbtskgd1oSzw%2FQjTiKeC60wwttLDo1pxkOK%2Fimg.png',
    name: '방탄소년단 뷔',
    peopleCount: 1,
  },
  {
    id: 28,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fnswni%2FbtskhLwjV8s%2FcPZfnRDkrNHN3tZNrfewGK%2Fimg.png',
    name: '방탄소년단 뷔',
    peopleCount: 1,
  },
  {
    id: 29,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FDdb3e%2Fbtskizbiate%2FdC8xMkKwAfxJoR1kMtO0a0%2Fimg.png',
    name: 'NCT 도영',
    peopleCount: 1,
  },
  {
    id: 30,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fc1k1IP%2FbtskrbHsymb%2FXEFWiTUAyW0Qy83B8EBHoK%2Fimg.png',
    name: '전 에이프릴 현주',
    peopleCount: 1,
  },
  {
    id: 31,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbeiPlg%2Fbtskllw5ZhE%2F1AMB8au9k7vl4RjrTJTTf0%2Fimg.png',
    name: '블랙핑크 지수',
    peopleCount: 1,
  },
  {
    id: 32,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FctahO3%2FbtskiTOqeOO%2FhGKwp45IfofditAPbAghC0%2Fimg.jpg',
    name: '침착맨',
    peopleCount: 1,
  },
  {
    id: 33,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FAhrkK%2FbtskgINUXtw%2FFPn1Nh3Z3pqcKpDNej1nn1%2Fimg.jpg',
    name: '짱구',
    peopleCount: 1,
  },
  {
    id: 34,
    src: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F2yo13%2Fbtskk8R3s7f%2F9yekE0eJf20PCjTXGtDek1%2Fimg.jpg',
    name: '짱구-수지',
    peopleCount: 1,
  },
];

/*


{
    id: 0,
    src: "",
    name: "",
    peopleCount: 1,
  },

  */
