import styled from '@emotion/styled';
import { ActivityComponentType } from '@stackflow/react';

import { AppScreen } from '../../stackflow/components';
import { useEffect, useRef, useState } from 'react';

import heart_default from '../../assets/Icon/heart_default.png';
import heart_selected from '../../assets/Icon/heart_selected.png';

import home_default from '../../assets/Icon/home_default.png';
import home_selected from '../../assets/Icon/home_selected.png';
import Header from './components/Header';
import Contents from './components/Contents';
import { useFlow } from '../../stackflow';
import DownloadAppDialog from '../DownloadAppDialog';

const HomePage: ActivityComponentType = () => {
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
  const [navigationValue, setNavigationValue] = useState('home');

  const contentsRef = useRef<HTMLDivElement>(null);
  const handleNavItem = (value: string) => {
    contentsRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    setNavigationValue(value);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const isApp = localStorage.getItem('isApp');
      if (!isApp) {
        setOpenDownloadDialog(true);
      }
    }, 600);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <AppScreen noAppBar>
      <Wrapper>
        <InnerWrapper ref={contentsRef}>
          <Header navigationValue={navigationValue} />
          <Contents navigationValue={navigationValue} />
        </InnerWrapper>

        <BottomNavigationWrapper>
          <NavItem onClick={() => handleNavItem('home')} selected={navigationValue === 'home'}>
            <Icon src={navigationValue === 'home' ? home_selected : home_default} />
            <label>홈</label>
          </NavItem>
          <NavItem onClick={() => handleNavItem('stored')} selected={navigationValue === 'stored'}>
            <Icon src={navigationValue === 'stored' ? heart_selected : heart_default} />
            <label>보관함</label>
          </NavItem>
        </BottomNavigationWrapper>
      </Wrapper>
      <DownloadAppDialog open={openDownloadDialog} onClose={() => setOpenDownloadDialog(false)} />
    </AppScreen>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;

const BottomNavigationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const NavItem = styled.div<{ selected: boolean }>`
  padding: 0.3438rem;
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;

  label {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #8b919c;

    ${({ selected }) => selected && `color: #1E2024`};
  }
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

export default HomePage;
